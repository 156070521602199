import { z } from 'zod'

const MAX_FILE_SIZE = 5000000
export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]

export const postSchema = z.object({
  title: z.string().min(5),
  describtion: z.string().min(0),
  picture: z
    .any()
    .refine((files) => files?.length == 1, 'Image is required.')
    .refine(
      (files) => files?.[0]?.size <= MAX_FILE_SIZE,
      `Max file size is 5MB.`,
    )
    .refine(
      (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
      '.jpg, .jpeg, .png and .webp files are accepted.',
    ),
  /*password: z.string().min(10, 'Password must be at least 10 characters'),
    confirmPassword: z.string(),*/
})
/*.refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  })*/

export type TpostSchema = z.infer<typeof postSchema>
