import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { Contact, Projects, About } from "./pages"
import { Navbar } from "./components"
import Layout from "./containers/Layout"
import AddNewPost from "./features/posts/NewPost"
import Post from "./pages/Post"
import NewPost from "./features/posts/NewPost"
import { NewHome } from "./pages/NewHome"
import GoToTop from "./components/GoToTop"
import ProjectDetails from "./components/ProjectDetails"

import "./index.css"
import Upload from "./pages/Upload"

const App = () => {
  return (
    <main>
      <Router>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <Navbar />
                <GoToTop />

                <Routes>
                  <Route path="/" element={<NewHome />} />

                  <Route path="/about" element={<About />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/project-details" element={<ProjectDetails />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/new-post" element={<NewPost />} />
                  <Route path="/post" element={<Post />} />
                  <Route path="/upload" element={<Upload />} />
                </Routes>
              </>
            }
          />
          <Route path="/dashboard/*" element={<Layout />} />
        </Routes>
      </Router>
    </main>
  )
}

const defauldContainer = () => <div></div>

export default App
