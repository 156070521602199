import { useState, useEffect } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { RiMenu3Fill } from "react-icons/ri"
import { IoMdClose } from "react-icons/io"
const Navbar = () => {
  let Links = [
    {
      name: "home",
      link: "/",
    },
    {
      name: "about",
      link: "/about",
    },
    {
      name: "projects",
      link: "/projects",
    },
    {
      name: "contact",
      link: "/contact",
    },
  ]
  let [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    // this statement for close the menu when navigated
    if (open) {
      setOpen(false)
    }
  }, [location])
  return (
    <div
      className={`${
        open ? "main-bg" : "bg-[#1a171bc4]"
      } p-6 sticky top-0 z-10 my-[15px] backdrop-blur-3xl transition-all duration-500 ease-in`}
    >
      <div
        className=" max-w-[100%] w-[1240px] flex justify-between items-center"
        style={{ margin: "0px auto" }}
      >
        <div>
          <NavLink to="/" className="text-2xl font-[700] ">
            mashady
          </NavLink>
        </div>
        <div>
          <div
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
            onClick={() => setOpen(!open)}
          >
            {!open ? <RiMenu3Fill /> : <IoMdClose />}
          </div>
          <ul
            className={`md:flex md:items-center p-6 md:p-0  absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto  transition-all duration-500 ease-in ${
              open ? "top-20 main-bg " : "top-[-490px]"
            }`}
          >
            {Links.map((link, i) => (
              <li
                key={i}
                className="md:ml-[40px] p-2 md:p-0 text-[19px] capitalize"
              >
                <NavLink to={link.link}>{link.name}</NavLink>
              </li>
            ))}

            <a
              target="_blank"
              href="https://drive.google.com/file/d/1Uwv--Msz9DsBrngEvNokq-c20S1macZu/view?usp=sharing"
            >
              <button className="btn md:ml-[40px]">
                {/**
             *  <a href="../assets/resume.pdf" download>
                Resume
              </a>
             *
             *
             */}
                Resume
              </button>
            </a>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
