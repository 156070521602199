import { Link, NavLink } from "react-router-dom"
import { FaGithub } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { FaLinkedin } from "react-icons/fa"

import "./style.css"
export default function Footer() {
  return (
    <>
      <div className="flex flex-col min-h-[60vh] simi-dark-bg justify-between py-8 pl-[3%] rounded-t-3xl footer-background">
        <div className="py-6">
          <span className="text-[17px] md:text-[25px]">
            lets get this party started
          </span>
          <h2 className="md:text-[40px] text-[22px] text-b break-words cursor-pointer">
            <a href="mailto:muhammedmashady@gmail.com?subject=General%20Notes%20about%20Website">
              muhammedmashady@gmail.com
            </a>
          </h2>
        </div>
        <div>
          <div className="flex-row flex mb-[1.5rem]">
            <ul className="text-[17px] md:text-[21px] capitalize mr-[30px]">
              <li>
                <NavLink to="/projects">projects</NavLink>
              </li>
              <li>
                <NavLink to="/about">about</NavLink>
              </li>
              <li>
                <NavLink to="/resume">resume</NavLink>
              </li>
            </ul>
            <ul className="text-[17px] md:text-[21px] capitalize">
              <li>
                <NavLink to="/stories">stories</NavLink>
              </li>
              <li>
                <NavLink to="/contact">contact us</NavLink>
              </li>
            </ul>
          </div>
          <div className="flex mb-3">
            <Link to="https://github.com/mashady" target="_blank">
              <FaGithub className="secondary-text text-2xl mr-3" />
            </Link>
            <Link to="https://twitter.com/mashadythedev" target="_blank">
              <FaXTwitter className="secondary-text text-2xl mr-3" />
            </Link>
            <Link
              to="https://www.linkedin.com/in/muhammedmashady/"
              target="_blank"
            >
              <FaLinkedin className="secondary-text text-2xl" />
            </Link>
          </div>
          <div>
            <div>
              © 2024 <strong>Samir Mashady</strong>. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
