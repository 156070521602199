import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { routes, RoutesTypes } from "../routes"
import GoToTop from "../components/GoToTop"

export function PageContent() {
  return (
    <div className="h-full min-h-screen simi-dark-bg px-8 py-8">
      <GoToTop />
      <Routes>
        {routes.map((route: RoutesTypes, key: number): any => {
          return (
            <>
              <Route
                key={key}
                path={route.path}
                element={<route.component />}
              />
            </>
          )
        })}
      </Routes>
    </div>
  )
}

export default PageContent
