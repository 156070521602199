import { projects, realProject } from "../constants"
import { Link } from "react-router-dom"
import projectCover from "../assets/fin.jpg"
import BeInTouch from "../components/BeInTouch"
import { useState } from "react"
import Modal from "../components/Modal"
import Footer from "../components/Footer/Footer"
const Projects = () => {
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState([])
  if (visible) {
    document.body.style.overflow = "hidden"
  } else document.body.style.overflow = "unset"
  const handleClcik = (project: any) => {
    setModalData(project)
    setVisible(true)
    console.log(project)
  }
  const handleClose = () => {
    setVisible(false)
  }
  return (
    <>
      <div className="relative">
        <section
          className={`max-w-[1240px] mx-auto pt-12 px-4 ${
            visible && "overflow-hidden"
          }`}
        >
          <div className="mb-16">
            <div>
              <h1 className="text-3xl font-bold leading-7 sm:truncate   ">
                Projects 🔥
              </h1>

              <p className="text-[22px] md:text-[24px] mt-5">
                As a dedicated software developer, I've had the privilege of
                working on a diverse range of projects that showcase my passion
                for creating innovative solutions. These endeavors include
                developing web applications, mobile apps, and backend systems.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 gap-10 justify-between ">
              {realProject.map((project) => (
                <div
                  className=" rounded "
                  key={project.name}
                  style={{ backgroundColor: "" }}
                >
                  <div className="flex flex-col">
                    <img
                      src={project.cover}
                      alt={project.name}
                      className="object-fit   rounded-md shadow-lg cursor-pointer"
                    />
                    <h4 className="text-2xl ubuntu-bold mt-2 cursor-pointer">
                      {project.name}
                    </h4>
                    <p className="mt-2 text-nowrap overflow-hidden truncate ">
                      {project.description}
                    </p>
                    <div className="mt-5 flex items-center justify-between gap-2">
                      <Link
                        to={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#6b69f2" }}
                        className="ubuntu-bold underline"
                      >
                        Github repo
                      </Link>
                      <Link
                        to={project.demoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#6b69f2" }}
                        className="ubuntu-bold"
                      >
                        Live demo
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr className="border-[#79747b]" />
          <BeInTouch />
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Projects
