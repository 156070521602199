import PageContent from "./PageContent"
import Sidebar from "./Sidebar"
function Layout() {
  return (
    <>
      <div className="">
        <aside className="w-[280px] fixed overflow-auto h-full  scrl-br ">
          <Sidebar />
        </aside>
        <div className="ml-[280px]">
          {/**
           *
          <PageContent />
           *
           *
           */}
          <PageContent />
        </div>
      </div>
    </>
  )
}

export default Layout
