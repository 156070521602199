import { IoMdAdd } from "react-icons/io"
import { FaImage } from "react-icons/fa6"
import { useState } from "react"
import { ACCEPTED_IMAGE_TYPES } from "../lib/types"

export default function ImageInput({
  name,
  errors,
  register,
  isDirty,
  isValid,
}: any) {
  const [file, setFile] = useState("")
  const url = "https://api.cloudinary.com/v1_1/dadhuuwz3/image/upload"
  const habdleFileUploadChange = (e: any) => {
    console.log(isDirty)
    console.log(isValid)

    // for this input only we want t o make a live validation
    if (isValid) console.log(isValid)
    if (e.target.files.length !== 0) {
      if (ACCEPTED_IMAGE_TYPES.includes(e.target.files[0].type)) {
        setFile(URL.createObjectURL(e.target.files[0]))
      } else {
        setFile("")
      }
    } else {
      return null
    }

    /*
    // here is our cloudinary upload
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      formData.append("file", file)
      formData.append("upload_preset", "bjnlyyzd")

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.status
        })
        .then((data) => {
          console.log(data)
        })
    }*/
  }
  const handleBlured = () => {
    console.log("blured")
  }
  return (
    <div className="flex mb-4">
      <div className="basis-1/4 ml-2 mt-3 text-[#dbd7df] text-[1.1rem] capitalize">
        add your own cover
      </div>
      <div className="basis-1/2">
        {file && (
          <img
            src={file}
            className="object-cover h-48 w-full rounded-md shadow-lg cursor-pointer"
          />
        )}
        <label className="">
          {!file && (
            <div
              className={`bg-inherit  border-[2px]  mb-2 cursor-pointer ${
                errors ? "border-[#FF5254]" : ""
              } border-[#39353B] focus:border-[#6b69f2]  text-[#dbd7df] text-[16px] rounded-lg  w-full p-2 mt-2.5 transition duration-300 ease-out outline-none h-32 flex items-center justify-center`}
            >
              <FaImage fontSize={30} color="#dbd7df" />
            </div>
          )}
          {errors && (
            <p className="mb-2 bg-[#492c38] text-[#dbd7df] p-2 rounded">
              {errors.message}
            </p>
          )}
          <div className="btn rounded-lg cursor-pointer w-full h-16 p-2 mt-2.5 transition duration-300 ease-out outline-none flex justify-center items-center">
            <IoMdAdd fontSize={25} />
          </div>

          <input
            id="picture"
            type="file"
            hidden
            {...register(name, {
              onChange: (e: any) => habdleFileUploadChange(e),
              onBlur: () => handleBlured(),
            })}
          />
        </label>
      </div>
    </div>
  )
}
