/* eslint-disable prettier/prettier */
import { skills } from "../constants"
import BeInTouch from "../components/BeInTouch"
import Footer from "../components/Footer/Footer"

const About = () => {
  return (
    <>
      <section className="max-w-[1240px] mx-auto pt-12 px-4">
        <h1 className="text-3xl ubuntu-bold">Hey, its abusamir</h1>
        <div className="mt-5 flex flex-col gap-3 ">
          <p className="text-[22px] md:text-[24px]">
            Software Developer based in Egypt, specializing in Full web app
            building in both ui and server side, also buliding mobile
            application.
          </p>
        </div>
        <div className="flex flex-col mt-10 mb-16">
          <h3 className="text-2xl ubuntu-bold">Skills & Technologies</h3>
          <div className="flex flex-wrap gap-10 mt-6 justify-center md:justify-start">
            {skills.map((skill) => (
              <div className="" key={skill.name}>
                <div className="rounded-xl flex justify-center items-center">
                  <img
                    src={skill.imageUrl}
                    alt={skill.name}
                    className=" object-contain w-[7rem] h-[8rem]"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr className="border-[#79747b]" />
        <BeInTouch />
      </section>
      <Footer />
    </>
  )
}

export default About
