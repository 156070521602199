import { IoCloseSharp } from "react-icons/io5"
import placeholder from "../assets/plchldr.jpg"

import { FiShare } from "react-icons/fi"
import avatar from "../assets/avatar.png"
export default function ProjectDetails() {
  return (
    <>
      <div className="bg-main rounded-t-lg relative ">
        <div className="max-w-4xl mx-auto p-4 pt-[3rem]">
          <h1 className="text-[24px] font-bold text-[#f5f1f9] tracking-[-0.011em]">
            name
          </h1>
          <div className="flex items-center  justify-between py-6">
            <div className="flex  items-center">
              <div className="">
                <img
                  src={avatar}
                  alt="avatar"
                  className=" w-[48px] rounded-full"
                />
              </div>
              <div className="ml-4">
                <div className="text-[17px] font-bold">Name</div>
                <div className="text-[#6b69f2] text-[15px]">
                  Available fo work
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <FiShare
                fontSize={22}
                className="mr-4 text-[#79747b] hover:text-[#f5f1f9] general-animation cursor-pointer"
              />
              <button className="h-[40px] bg-[#6b69f2] hover:bg-[#5956f0] general-animation px-[20px] text-[15px] rounded-full ">
                Contact
              </button>
            </div>
          </div>
          <div className=" my-4">
            <img
              className="rounded h-full w-full max-h-[450px]"
              src={placeholder}
              alt="alt"
            />
          </div>
          <div className="flex justify-center">
            <p className="text-[20px] my-10 ">
              Hi folks! 👋 <br />
              Here is an UI exploration for E-book website where mine focus was
              to make it minimalistic and easy to use .<br /> Press ❤️ for more.
              Share your thoughts in comments 💬 <br />
              Have a project? Drop me a line:{" "}
              <span className="text-[#6b69f2] hover:underline decoration-[#6b69f2] general-animation">
                muhammedmashady@gmail.com
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
