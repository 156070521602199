import { Link, NavLink } from "react-router-dom"
import { skills } from "../../constants/index"
import { IoIosArrowDown } from "react-icons/io"

function Skills() {
  return (
    <div className=" ">
      <div className="flex flex-col">
        <div className="text-[14px]">
          <span className="cursor-pointer hover:underline">
            <Link to="/dashboard">Pages</Link>
          </span>
          <span className="mx-2">/</span>
          <span className="cursor-pointer  hover:underline">
            <Link to="/dashboard/skills">Skills</Link>
          </span>
        </div>
        <h1 className="text-3xl font-bold cursor-pointer">Skills</h1>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <div className="flex w-auto mr-2">
              <select
                id="countries"
                className="bg-gray-50 border border-[#79747b] border-opacity-25 main-bg  text rounded-lg focus:ring-[#6B69F2] focus:border-[#6B69F2] outline-none block w-full p-2.5 "
              >
                <option selected>Choose a chapter</option>
                <option value="US">Backend</option>
                <option value="CA">Frontend</option>
              </select>
            </div>
            <button className="btn h-[40px]">
              <NavLink to="/projects">Filter</NavLink>
            </button>
          </div>
          <button className="btn h-[40px]">
            <NavLink to="/projects">+ Add Skill</NavLink>
          </button>
        </div>
      </div>
      {/**
       * main-bg p-8 rounded-[10px] shadow-2xl
       *
       */}
      <div className=" mt-4">
        <div className="flex flex-col">
          <h3 className="text-2xl ubuntu-bold hidden">Skills & Technologies</h3>
          <div className="flex flex-wrap gap-10 mt-6 ">
            {skills.map((skill) => (
              <div className="" key={skill.name}>
                <div className="rounded-xl flex justify-center items-center main-bg h-[5rem] w-[6rem] cursor-pointer shadow-2xl">
                  <img
                    src={skill.imageUrl}
                    alt={skill.name}
                    className=" object-contain w-[4rem] h-[3rem]"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
