import { Link, NavLink } from "react-router-dom"
import { FaChevronRight } from "react-icons/fa"
import sidebar from "../routes/sidebar.js"

function Sidebar() {
  return (
    <div className=" text-2xl text-center">
      <div className="border-b-[1px] border-[#79747b] border-opacity-25 p-8">
        <Link to="/">mashady</Link>
      </div>
      <ul className="menu  pt-6 bg-base-100 min-h-full p-2 text-[#dbd7df]  text-base-content">
        {sidebarRoutes}
      </ul>
    </div>
  )
}

const sidebarRoutes = sidebar.map((route, index) => (
  <NavLink to={route.path} end={route.end} key={index}>
    {({ isActive, isPending, isTransitioning }) => (
      <li
        className={
          isActive
            ? "mb-2 font-semibold p-[0.5rem] text-[#6B69F2] rounded hover:bg-[#221e24] bg-[#221e24] text-lg flex items-center justify-between"
            : "mb-2 font-semibold p-[0.5rem] rounded hover:bg-[#221e24]  text-lg flex items-center justify-between"
        }
      >
        {route.name} <FaChevronRight />
      </li>
    )}
  </NavLink>
))

export default Sidebar
