import { Link, NavLink } from "react-router-dom"
import { CgOptions } from "react-icons/cg"
import { FaChevronLeft } from "react-icons/fa"
import { FaChevronRight } from "react-icons/fa"
import place from "../../assets/fin.jpg"

function Posts() {
  const handleAdding = () => {
    console.log("adding new post")
  }
  let fake = [
    { name: "one" },
    { name: "one" },
    { name: "one" },
    { name: "one" },
  ]
  return (
    <div>
      <div className="flex flex-col">
        <div className="text-[14px]">
          <span className="cursor-pointer hover:underline">
            <Link to="/dashboard">Pages</Link>
          </span>
          <span className="mx-2">/</span>
          <span className="cursor-pointer  hover:underline">
            {" "}
            <Link to="/dashboard/posts">Posts</Link>
          </span>
        </div>
        <h1 className="text-3xl font-bold cursor-pointer">Posts</h1>
      </div>
      <div className="my-6">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <div className="flex w-auto mr-2">
              <select
                id="countries"
                className="bg-gray-50 border border-[#79747b] border-opacity-25 main-bg  text rounded-lg focus:ring-[#6B69F2] focus:border-[#6B69F2] outline-none block w-full p-2.5 "
              >
                <option selected>Choose a chapter</option>
                <option value="US">Backend</option>
                <option value="CA">Frontend</option>
              </select>
            </div>
            <button className="btn h-[40px]">
              <NavLink to="/projects">Filter</NavLink>
            </button>
          </div>
          <button className="btn h-[40px]">
            <NavLink to="/projects">+ Add Skill</NavLink>
          </button>
        </div>
      </div>
      {/** card */}
      <div className="grid grid-cols-2 gap-10 mt-6">
        {/** card 1 */}

        {fake.map((f, i) => (
          <div className="main-bg rounded-[10px] shadow-2xl" key={i}>
            <div className="flex justify-between items-center  p-4 rounded-t-[10px] border-b-4 border-[#221e24]">
              <h1 className="text-xl">any title here</h1>
              <CgOptions className="text-xl secondary-text font-bold cursor-pointer" />
            </div>
            <div className="flex justify-between  p-4">
              <div className="text-[#79747b] text-[0.88rem] flex flex-col justify-between ">
                <div>
                  <p className="text-[#f5f1f9] text-[1rem] mb-4">Techs used</p>
                  <ul className="">
                    <li className="">angular</li>
                    <li>angular</li>
                    <li>angular</li>
                    <li>angular</li>
                  </ul>
                </div>

                <button className="btn mb-4">explore</button>
              </div>
              <div className="basis-1/2 ">
                <img
                  src={place}
                  className="w-[400px] rounded-[10px] shadow-2xl"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <hr className="border-[#79747b] border-opacity-25 my-8" />
      <div className="flex justify-between items-center ">
        <div>
          <span className="">450 results</span>
        </div>
        <div className=" flex justify-center items-center">
          <FaChevronLeft className="text-[0.8rem] cursor-pointer" />
          <div className="mx-4 text-[0.9rem]">
            <input
              type="text"
              className="main-bg outline-none border-0 rounded w-[2rem] h-[2rem] pl-[16%] mr-[0.5rem] placeholder:text-white"
              placeholder="1"
            />
            <span className="">of 50</span>
          </div>

          <FaChevronRight className="text-[0.8rem] cursor-pointer" />
        </div>
      </div>
    </div>
  )
}

export default Posts
