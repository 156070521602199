import placeholder from "../assets/plchldr.jpg"
import { CiHeart } from "react-icons/ci"
import { FiShare } from "react-icons/fi"
import { FaBookmark } from "react-icons/fa6"

export default function Post() {
  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="max-h-[450px] my-4">
        <img className="rounded h-full w-full" src={placeholder} alt="alt" />
      </div>
      <div className="">
        <div className="uppercase text-[1.1rem] my-6">
          <span className="text-[#6b69f2] cursor-pointer ">
            intro to my story
          </span>{" "}
          <span className="mx-2">/</span> the magic created bu abusamir
        </div>
        <div className="">
          <h1 className="text-[42px] font-bold text-[#f5f1f9] tracking-[-0.011em]">
            Story about the magic created by abusamir
          </h1>
        </div>
        <div className=" flex justify-between border-y-[0.1px] border-[#79747b1f] py-5 px-2 my-6">
          <div className="">
            <CiHeart
              fontSize={25}
              className="text-[#79747b] hover:text-[#f5f1f9] general-animation cursor-pointer"
            />
          </div>
          <div className="flex">
            <FiShare
              fontSize={22}
              className="mr-4 text-[#79747b] hover:text-[#f5f1f9] general-animation cursor-pointer"
            />
            <FaBookmark
              fontSize={22}
              className="text-[#79747b] hover:text-[#f5f1f9] general-animation cursor-pointer"
            />
          </div>
        </div>
        <div className="">
          <h1 className="text-[24px] tracking-[-0.016em] font-bold my-6">
            Introduction
          </h1>
          <p className="text-[20px] break-words my-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus error veritatis alias id cumque tenetur sit, quidem
            repellendus libero vitae expedita, tempora quam. Eum aliquam nam
            totam ducimus natus!
          </p>
          <p className="text-[20px] break-words my-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus error veritatis alias id cumque tenetur sit, quidem
            repellendus libero vitae expedita, tempora quam. Eum aliquam nam
            totam ducimus natus!
          </p>
          <p className="text-[20px] break-words my-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus error veritatis alias id cumque tenetur sit, quidem
            repellendus libero vitae expedita, tempora quam. Eum aliquam nam
            totam ducimus natus!
          </p>
          <p className="text-[20px] break-words my-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus error veritatis alias id cumque tenetur sit, quidem
            repellendus libero vitae expedita, tempora quam. Eum aliquam nam
            totam ducimus natus!
          </p>
          <p className="text-[20px] break-words my-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus error veritatis alias id cumque tenetur sit, quidem
            repellendus libero vitae expedita, tempora quam. Eum aliquam nam
            totam ducimus natus!
          </p>
        </div>
      </div>
    </div>
  )
}
