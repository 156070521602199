const sidebar = [
  {
    path: "/dashboard",
    name: "Dashboard",
    end: true,
  },
  {
    path: "/dashboard/posts",
    name: "Posts",
    end: false,
  },
  {
    path: "/dashboard/projects",
    name: "Projects",
    end: false,
  },
  {
    path: "/dashboard/skills",
    name: "Skills",
    end: false,
  },
]

export default sidebar
