import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"

export interface CounterState {
  value: {
    id: number
    name: string
  }
  status: "idle" | "loading" | "failed"
}

const initialState = {
  value: {},
  status: "idle",
}
export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    reducer(state, action: PayloadAction<CounterState>) {
      state.value = action.payload
    },
  },
})

export default postsSlice.reducer
