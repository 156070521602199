import { Link, NavLink } from "react-router-dom"
import { MdWebStories } from "react-icons/md"
import { useState } from "react"
import placeholder from "../assets/plchldr.jpg"

function Dashboard() {
  const links = [
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
    { name: "Earning", icon: <MdWebStories className="text-[1.2rem] " /> },
  ]

  return (
    <div className=" ">
      <div className="flex flex-col">
        <div className="text-[14px]">
          <span className="cursor-pointer hover:underline">
            <Link to="/dashboard">Pages</Link>
          </span>
          <span className="mx-2">/</span>
          <span className="cursor-pointer  hover:underline">
            {" "}
            <Link to="/dashboard">Dashboard</Link>
          </span>
        </div>
        <h1 className="text-3xl font-bold cursor-pointer">Dashboard</h1>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-6">
        {links.map((link, index) => (
          <div
            className="main-bg flex flex-row rounded-[20px] shadow-2xl cursor-pointer"
            key={index}
          >
            <div className=" ml-4 flex justify-center items-center h-[90px]">
              <div className="simi-dark-bg w-12 h-12 flex justify-center items-center rounded-full">
                <MdWebStories className="text-[1.2rem] " />
              </div>
            </div>
            <div className=" flex justify-center items-center ml-4">
              <div className="flex flex-col">
                <div className="text-sm font-mediumn text-[#dbd7d]  ">
                  Earning
                </div>
                <div className="text-xl font-bold">350.00$</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className=" mt-6 p-6 rounded-[20px]">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl"> Feature project</h2>
          <button className="btn h-[40px]">
            <NavLink to="/projects">Change</NavLink>
          </button>
        </div>
        <div className="flex ">
          <div className=" my-4 max-w-[100%]  mt-6 relative">
            <img
              className=" h-full w-full rounded-[14px] "
              src={placeholder}
              alt="alt"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
