import React from "react"

export const Upload = () => {
  const cloudinaryUrl = "https://api.cloudinary.com/v1_1/dadhuuwz3/image/upload"

  const handleImageChanges = (e: any) => {
    console.log(e.target.files)
    let ImageArray: any = []
    const formData = new FormData()
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i]
      formData.append("file", file)
      formData.append("upload_preset", "bjnlyyzd")

      fetch(cloudinaryUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return ImageArray.push(response.url) // collect all url in on array to handle it
        })
        .finally(() => {
          console.log(ImageArray)
        })
    }
  }
  return (
    <div>
      uploading images
      <input
        id="picture"
        type="file"
        multiple
        onChange={(e) => handleImageChanges(e)}
      />
    </div>
  )
}

export default Upload
