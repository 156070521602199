import { NavLink } from "react-router-dom"
import placeholder from "../assets/plchldr.jpg"
import placeholder2 from "../assets/appHeaderMovieHub.png"
const realPlaceholder =
  "https://raw.githubusercontent.com/mashady/store-by-angular/main/promo/Capture1.PNG"
const mainProject =
  "https://raw.githubusercontent.com/mashady/issue-tracker-by-abu/main/promo/issues-tracker-promo.png"
import { skills } from "../constants"
import skillPlacholder from "../assets/icons/typescript.svg"
import { FaArrowRight } from "react-icons/fa"
import Footer from "../components/Footer/Footer"
import BeInTouch from "../components/BeInTouch"
export function NewHome() {
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-4">
        <div className="flex flex-col justify-center items-center text-center mt-2 md:mt-[10rem]">
          <h1 className="text-[45px] md:text-[60px] ubuntu-bold px-[20px]">
            Web Developer and Crafting Digital
          </h1>
          <p className="text-[23px] md:text-[26px] ubuntu-medium max-w-[750px]">
            Creating high quality Front End Templates also write the sever side
            codes.
          </p>
          <button className="btn mt-6 md:mt-4 h-[54px]">
            <NavLink to="/projects">Explore Projects Now</NavLink>
          </button>

          <div className=" my-4 max-w-[900px]  mt-16 relative">
            <img
              className=" h-full w-full rounded-[14px] "
              src={mainProject}
              alt="alt"
            />
          </div>
          <div className="flex flex-col my-16 justify-center items-center text-center">
            <div className="text-[17px]">Technologies to make code better:</div>
            <div className="grid grid-cols-4 md:grid-cols-8  gap-6 mt-4 ">
              {skills.slice(0, 8).map((skill, i) => {
                return (
                  <div
                    className="rounded-xl flex justify-center items-center"
                    key={i}
                  >
                    <img
                      src={skill.imageUrl}
                      className=" object-contain w-[5rem] h-[8rem]"
                    />
                  </div>
                )
              })}
            </div>
            <div className="flex justify-end w-full  my-8 md:mt-4  secondary-text">
              <span className=" ">
                <NavLink
                  to="/about"
                  className="text-xl hover:underline  cursor-pointer flex justify-center items-center"
                >
                  View more
                </NavLink>
              </span>
            </div>
          </div>
        </div>
        <hr className="border-[#79747b]" />

        <BeInTouch />
      </div>
      <Footer />
    </>
  )
}
