import { useForm, useFieldArray } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { TpostSchema, postSchema } from "../../lib/types"
import ImageInput from "../../components/ImageInput"
function NewPost() {
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
      isDirty,
      touchedFields,
      submitCount,
      isValid,
    },
    reset,
    setError,
    setValue,
    control,
  } = useForm<TpostSchema>({
    mode: "onChange",
    resolver: zodResolver(postSchema),
  })

  const onSubmit = async (data: TpostSchema) => {
    console.log(data)
  }
  return (
    <section className="contr">
      <h1>add new post</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ImageInput
          name="picture"
          register={register}
          errors={errors.picture}
          isDirty={isDirty}
          isValid={isValid}
        />
        <div className="flex">
          <label className="basis-1/4  ml-2 mt-3 text-[#dbd7df] text-[1.1rem] capitalize">
            title
          </label>
          <div className="basis-1/2">
            <input
              className={
                errors.title
                  ? "input mb-4 border-[#FF5254]"
                  : "input mb-4  border-[#39353B] focus:border-[#6b69f2]"
              }
              {...register("title")}
              placeholder={`Add your own title`}
            />
            {errors.title && (
              <p className="mb-2 bg-[#492c38] text-[#dbd7df] p-2 rounded">
                {errors.title?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex">
          <label className="basis-1/4  ml-2 mt-3 text-[#dbd7df] text-[1.1rem] capitalize">
            describtion
          </label>
          <div className="basis-1/2">
            <textarea
              className={
                errors.describtion
                  ? "input mb-4 border-[#FF5254]"
                  : "input mb-4  border-[#39353B] focus:border-[#6b69f2]"
              }
              placeholder={`Add your own describtion`}
              {...register("describtion")}
            ></textarea>

            {errors.describtion && (
              <p className="mb-2 bg-[#492c38] text-[#dbd7df] p-2 rounded">
                {errors.describtion.message}
              </p>
            )}
          </div>
        </div>
        <div className="button-container border-t-[1px] p-4 flex justify-end">
          <button disabled={isSubmitting} type="submit" className="btn">
            save
          </button>
        </div>
      </form>
    </section>
  )
}

export default NewPost
