import Footer from "../components/Footer/Footer"
import { FaGithub } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { FaLinkedin } from "react-icons/fa"
import { Link } from "react-router-dom"
const Contact = () => {
  return (
    <>
      <section className="max-w-[1240px] mx-auto mt-2 mb-6 md:pt-[7rem] md:pb-[11rem] px-6 md:px-4">
        <h1 className="text-4xl md:text-[65px] ubuntu-bold mb-2 md:mb-8">
          Hello world!
        </h1>
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-between text-2xl lg:text-[30px] py-6 border-t-2">
            <div className="">For project inqueries and general Qs:</div>
            <div className="cursor-pointer break-words">
              <a href="mailto:muhammedmashady@gmail.com?subject=General%20Notes%20about%20Website">
                muhammedmashady@gmail.com
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between text-2xl lg:text-[30px] py-6 border-t-2">
            <div className="">Join our mealing list: </div>
            <div className="cursor-pointer">Newsletter signup</div>
          </div>
          <div className="flex flex-col md:flex-row justify-between text-2xl lg:text-[30px] py-6 border-y-2">
            <div className="">Tour via social: </div>
            <div className=" flex items-center lg:justify-center mt-2 lg:mt-0">
              <div className="flex items-center lg:justify-center">
                <Link to="https://github.com/mashady" target="_blank">
                  <FaGithub className="secondary-text text-3xl mr-3 cursor-pointer" />
                </Link>
                <Link to="https://twitter.com/mashadythedev" target="_blank">
                  <FaXTwitter className="secondary-text text-3xl mr-3 cursor-pointer" />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/muhammedmashady/"
                  target="_blank"
                >
                  <FaLinkedin className="secondary-text text-3xl cursor-pointer" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Contact
