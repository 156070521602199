import { Link } from "react-router-dom"

const BeInTouch = () => {
  return (
    <section className="cta my-[6rem]">
      <p className="cta-text">
        Be in touch with us <br className="sm:block hidden" /> and build your
        own world
      </p>
      <Link to="/contact" className="btn">
        Contact
      </Link>
    </section>
  )
}

export default BeInTouch
