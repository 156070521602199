import Dashboard from "../pages/Dashboard"
import Posts from "../features/posts/Posts"
import Projects from "../features/projects/AllProjects"
import AddNewProject from "../features/projects/AddNewProject"
import AddNewSnap from "../features/snaps/AddNewSnap"
import NewPost from "../features/posts/NewPost"
import Skills from "../features/snaps/Skills"

export interface RoutesTypes {
  path: string
  component: any
}

export const routes: RoutesTypes[] = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/posts",
    component: Posts,
  },
  {
    path: "/posts/new",
    component: NewPost,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/add-project",
    component: AddNewProject,
  },
  {
    path: "/skills",
    component: Skills,
  },
  {
    path: "/add-snap",
    component: AddNewSnap,
  },
]
